import { useEffect, useState } from 'react';
import CampaignBox from '../../components/CampaignBox';
import Disclaimer from '../../components/Disclaimer';
import HeroCTA from '../../components/HeroCTA';
import Layout from '../../components/Layout';
import PortableText from '../../components/PortableText';
import Section from '../../components/Section';
import AllArticlesButton from '../../components/cards/AllArticlesButton';
import ArticleCard from '../../components/cards/ArticleCard';
import { usedCarPageQuery } from '../../groq/pages/usedCarPage';
import { sanityClient } from '../../lib/sanity.server';
import { CampaignSingularType, MenuType, SettingsType, UsedCarPageType } from '../../types';
import fetchSanityData from '../../utils/fetchSanityData';
import CampaignCard from '../../components/cards/CampaignCard';
import SchemaRenderer from '../../components/schemaRenderer';

interface PageProps {
  data: {
    content: UsedCarPageType;
    menus: MenuType;
    settings: SettingsType;
  };
}

function UsedCarPage({ data }: PageProps) {
  const {
    menus,
    settings,
    content: {
      _id,
      description,
      overline,
      title,
      image,
      bodyText,
      seo,
      defaultSEO,
      featureArticles,
      formOverride,
    },
  } = data;

  const schemaMarkup = seo?.schemaMarkup;
  const [campaigns, setCampaigns] = useState<CampaignSingularType[] | undefined>(undefined);

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('usedCarPageCampaigns', {
        currentDocumentId: _id,
      });
      setCampaigns(response.campaigns);
    };

    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let disclaimerNo = 1;

  const campaignDisclaimer = campaigns?.filter((campaign) => campaign.disclaimer).length
    ? campaigns
        .filter((item) => item.disclaimer)
        .map(
          (campaign, index) => `${new Array(index + 1).fill('*').join('')}${campaign.disclaimer}`,
        )
        .join('\n')
    : '';

  return (
    <Layout settings={settings} menus={menus} seo={seo} defaultSEO={defaultSEO}>
      <HeroCTA
        title={title}
        image={image}
        overline={overline}
        description={description}
        primaryButton={{
          href: 'https://www.bruktbil.no/moller-bil',
          linkText: 'Se alle våre bruktbiler',
        }}
        formOverride={formOverride}
      />
      {schemaMarkup && (
        <SchemaRenderer
          schemaMarkup={schemaMarkup}
          pageContent={data.content}
          alwaysShowTypes={['Article']}
        />
      )}
      {!!campaigns?.length && (
        <Section>
          {campaigns.map((campaign) => {
            return campaign.campaignItem ? (
              <CampaignCard
                key={campaign._id}
                overline={campaign.overline}
                title={campaign.title}
                image={campaign.image}
                description={campaign.pitch}
                href={campaign.campaignItem.href}
              />
            ) : (
              <CampaignBox
                key={campaign._id}
                campaign={campaign}
                itemNo={campaign.disclaimer ? disclaimerNo++ : 0}
              />
            );
          })}
        </Section>
      )}
      {bodyText && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <PortableText value={bodyText} />
        </div>
      )}
      {/* ARTIKLER */}
      {!!featureArticles.length && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Relaterte artikler</h2>

          <div className="mb-10 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {featureArticles.map((article) => {
              return <ArticleCard key={article.title} article={article} />;
            })}
          </div>
          <AllArticlesButton />
        </Section>
      )}
      {campaignDisclaimer && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <Disclaimer text={campaignDisclaimer} />
        </div>
      )}
    </Layout>
  );
}

export async function getStaticProps() {
  const data = await sanityClient.fetch(usedCarPageQuery, { currentDocumentSlug: 'bruktbil' });
  if (!data.content) return { notFound: true };

  return {
    props: { data },
    revalidate: 1,
  };
}

export default UsedCarPage;
